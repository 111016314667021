document.addEventListener('DOMContentLoaded', () => {
  loadCSS();
  initFloatingFooter();
  initLimitedFloatingFooter();
  initModal();
  initLimitedModal();
});

window.addEventListener('load', () => {
  document.querySelector('.container h1').classList.add('loaded');
});

function loadCSS() {
  [
    // 'https://common.lp.koyamachuya.com/sb-36.lp.koyamachuya.com/css/style.css',
    'https://cdn.jsdelivr.net/npm/yakuhanjp@3.0.0/dist/css/yakuhanjp.min.css'
  ].forEach((uri) => {
    let link  = document.createElement('link');
    if (! link) return;
    link.href = uri;
    link.rel  = 'stylesheet';
    document.head.appendChild(link);
  });
};

/* ========  Start Floating Footer ======== */

function initFloatingFooter() {
  addEventListener('scroll', addShowClassToFloatingFooter, {passive: true});
}

function addShowClassToFloatingFooter() {
  let floatingFooter = document.getElementById('floatingfooter');
  if (! floatingFooter) return;
  floatingFooter.classList.add('show');
  removeEventListener('scroll', addShowClassToFloatingFooter);
}

function initLimitedFloatingFooter() {
  addEventListener('scroll', addShowClassToLimitedFloatingFooter, {passive: true});
}

function addShowClassToLimitedFloatingFooter() {
  let floatingFooter = document.getElementById('limitedfloatingfooter');
  if (!floatingFooter) return;
  floatingFooter.classList.add('show');
  removeEventListener('scroll', addShowClassToFloatingFooter);
}

/* ========  Start Modal ======== */

let _classCallCheck = (instance, Constructor) => {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

let _createClass = function () {
  function defineProperties(target, props) {
    props.forEach(descriptor => {
      descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    });
  }
  return (Constructor, protoProps, staticProps) => {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps); return Constructor;
  };
}();

function initModal() {
  let showButton = document.querySelector('#floatingfooter .container .button');
  let modalBlock = document.getElementById('modalBlock');
  if (
    ! showButton ||
    ! modalBlock
  ) return;
  return new ModalObject(modalBlock, 'modal__open', showButton);
}

function initLimitedModal() {
  let showButton = document.querySelector('#limitedfloatingfooter .container .button');
  let modalBlock = document.getElementById('modalBlock');
  if (
    !showButton ||
    !modalBlock
  ) return;
  return new ModalObject(modalBlock, 'modal__open', showButton);
}

let ModalObject = function() {
  function ModalObject(element, bodyOpenClass, trigger) {
    _classCallCheck(this, ModalObject);

    this.element = element;
    this.closeButton = this.element.getElementsByClassName('modal__close')[0];
    this.backdrop = this.element.getElementsByClassName('modal__backdrop')[0];
    this.trigger = trigger;
    this.bodyOpenClass = bodyOpenClass;
    this.body = document.body;

    // trigger
    this.trigger.addEventListener('click', this.showModal.bind(this));
    this.closeButton.addEventListener('click', this.close.bind(this));

    this.backdrop.addEventListener('click', this.close.bind(this));
    this.element.addEventListener('touchmove', function (event) { event.preventDefault(); });
  }

  // createClass
  _createClass(ModalObject, [{
    key: 'showModal',
    value: function showModal() {
      this.body.classList.add(this.bodyOpenClass);
      this.element.classList.add('open');
    }
  }, {
    key: 'close',
    value: function close() {
      this.element.classList.remove('open');
      this.body.classList.remove(this.bodyOpenClass);
    }
  }]);

  return ModalObject;
}();